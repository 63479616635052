import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";
import EventPanel from "../EventPanel/eventPanel.js";
import { Badge } from "@material-ui/core";
import { getEvents } from "../../../store/Event/action.js";
import { getCelebrations } from "../../../store/Celebration/action.js";
import { getPosts } from "../../../store/Posts/actions.js";
import PostPanel from "../PostPanel/postPanel.js";
import { removePostTab } from "../../../store/PostTabs/actions.js";
import TabPanel from "./TabPanel/tabPanel.js";
import CelebrationNotification from "../CelebrationNotificationContainer/celebrationNotificationContainer.js";
import PostList from "../PostsList/PostsList.js";
import "./homepageNavigation.style.css";
import {
  Assessment,
  Cake,
  Event,
  Description,
  Cancel
} from "@material-ui/icons";
import { usePrevious } from "../../../helpers/hooks";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

// The Homepage Navigation Component
function HomepageNavigationTabs({
  getEvents,
  getCelebrations,
  getPosts,
  events,
  celebrations,
  posts,
  postTabs,
  removePostTab
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { totalEventsForToday } = events;
  const { totalPostsUpdatedToday } = posts;
  const { totalCelebrationsToday } = celebrations;

  // This is similar to componentDidMount()
  useEffect(() => {
    getEvents();
    getCelebrations();
    getPosts();
  }, []);

  useEffect(() => {
    if (postTabs.length) {
      let { id, title } = postTabs[postTabs.length - 1];
      setValue(`${title}${id}`);
    } else {
      setValue(0);
    }
  }, [postTabs]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const closePostTab = (event, post) => {
    event.stopPropagation();
    setValue(0); // temporarily switch to the first one
    removePostTab(post);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Homepage Navigation Bar"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={
              <Badge
                className="tab"
                color="secondary"
                badgeContent={totalPostsUpdatedToday}
              >
                <Assessment /> <span>News</span>
              </Badge>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Badge
                className="tab"
                color="secondary"
                badgeContent={totalCelebrationsToday }
              >
                <Cake /> <span>Celebrations</span>
              </Badge>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Badge
                className="tab"
                color="secondary"
                badgeContent={totalEventsForToday}
              >
                <Event />
                <span> Events</span>
              </Badge>
            }
            {...a11yProps(2)}
          />
          {postTabs.map(p => (
            <Tab
              key={p.id}
              label={
                <div className="tab">
                  <Description />
                  <span>{p.title} </span>
                  <Cancel color="secondary" onClick={e => closePostTab(e, p)} />
                </div>
              }
              {...a11yProps(`${p.title}${p.id}`)}
              value={`${p.title}${p.id}`}
            />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <PostList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CelebrationNotification />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EventPanel />
      </TabPanel>
      {postTabs.map((p, i) => (
        <TabPanel key={p.id} value={value} index={`${p.title}${p.id}`}>
          <PostPanel post={p} />
        </TabPanel>
      ))}
    </div>
  );
}
const mapStateToProps = state => ({
  events: state.events,
  celebrations: state.celebrations,
  posts: state.posts,
  postTabs: state.postTabs
});
export default connect(mapStateToProps, {
  getEvents,
  getCelebrations,
  getPosts,
  removePostTab
})(HomepageNavigationTabs);
