import axios from 'axios';

async function send(method,url, data, options){
    
    if(!Object.values(METHODS).includes(method)){
        throw new Error("Method type is invalid");
    }
    return axios[method](url, data, options)
}

export const METHODS = {
    get: 'get',
    post: 'post',
    put: 'put',
    delete: 'delete'
}
export default {send, METHODS}