import React from "react";
import { Typography } from "@material-ui/core";
export default props => {
  const { history } = props;
  return (
    <div>
      <Typography variant="h6">History</Typography>
      <Typography>{history || ""}</Typography>
    </div>
  );
};
