import CMSClient from "../../services/CMSClientService.js";

const GET_SCHEDULES = "GET_SCHEDULES";
export const actions = { GET_SCHEDULES };

function getSchedulesAction(schedules) {
  return {
    type: actions.GET_SCHEDULES,
    schedules
  };
}

export function getSchedules() {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        let { data: masses } = await CMSClient.getMassSchedules();
        let {
          data: reconciliations
        } = await CMSClient.getReconciliationSchedule();
        let {
          data: eucharisticadorations
        } = await CMSClient.getEucharisticAdorations();
        let schedules = [
            {
                type: 'Mass Schedule',
                schedules: masses
            },
            { 
                type: 'Reconciliation',
                schedules: reconciliations
            },
            {
                type: 'Eucharistic Adorations',
                schedules: eucharisticadorations
            }
        ]

        dispatch(getSchedulesAction(schedules))
        resolve(schedules);
      } catch (err) {}
    });
  };
}
