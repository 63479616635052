import React, { Component } from "react";
import { connect } from "react-redux";
import { TextField, Typography, Button } from "@material-ui/core";
import "./dynamicForm.style.css";
class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        fields: []
      }
    };
  }
  UNSAFE_componentWillMount() {
    const { fields } = this.props;
    this.setState({ form: { fields } });
  }
  handleChange = event => {
    const { target } = event;
    this.setState(prev => {
      const fields = prev.form.fields.map(f => {
        if (f.name == target.name) {
          f.value = target.value;
        }
        return f;
      });
      return { ...prev, form: { fields } };
    });
    if (this.props.onChange) {
      this.props.onChange(this.state, event);
    }
  };
  handleSubmit = event => {
    event.preventDefault();
    this.props.handleSubmit(this.state);
  };
  render() {
    const { fields } = this.state.form;
    const { title } = this.props;
    return (
      <form
        className="flex-column-start dynamic-form"
        onSubmit={this.handleSubmit}
      >
        <Typography variant="h6">{title}</Typography>
        {fields.map((f, i) => {
          return (
            <TextField
              name={f.name}
              key={i}
              type={f.type}
              required={f.required}
              variant="filled"
              multiline={f.type == "textarea"}
              rowsMax={20}
              rows={f.rows}
              value={f.value}
              onChange={this.handleChange}
              label={f.label}
            />
          );
        })}
        <Button variant="contained" type="submit" color="primary">
          Submit
        </Button>
      </form>
    );
  }
}
const mapStateToProps = state => ({
  contactRequestStatus: state.contactRequestStatus
});
export default connect(mapStateToProps)(ContactForm);
