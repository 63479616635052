import httpHelper from "../helpers/httpRequestHelper.js";
var baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : process.env.REACT_APP_PROXY_URL;
export async function randomVerse() {
  try {
    return (
      await httpHelper.send(httpHelper.METHODS.get, `${baseURL}/bible/verseoftheday`)
    ).data;
  } catch (err) {
    throw err;
  }
}

export default { randomVerse };
