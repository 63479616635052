import React, { Component } from "react";
import { connect } from "react-redux";
import email from "../../../services/emailService.js";
import DynamicForm from "../../DynamicForm/dynamicForm.js";
import fields from "./fields.json";
class ContactForm extends Component {
  handleSubmit(formData) {
    const { fields } = formData.form;
    let params = fields.reduce((acc, next) => {
      if (next.type === "email") {
        acc["from_email"] = next.value;
      } else if (next.name === "firstName") {
        acc["from_name"] = next.value;
      } else if (next.name === "lastName") {
        acc["from_name"] += ` ${next.value}`;
      } else if (next.name === "message") {
        acc["message"] = next.value;
      }
      return acc;
    }, {});
    email.send(params);
  }
  render() {
    return (
      <div>
        <DynamicForm
          handleSubmit={this.handleSubmit}
          title="Contact Us"
          fields={fields}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  contactRequestStatus: state.contactRequestStatus
});
export default connect(mapStateToProps)(ContactForm);
