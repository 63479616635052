import {actions} from './action.js';

export default (state = {}, action)=>{
    switch(action.type){
        case actions.GET_ABOUT:
            return action.about;
        default: 
            return state;
    }
}
