import { createMuiTheme } from '@material-ui/core/styles';

// contains default theme for all components
export default createMuiTheme({
  palette: {
    primary: {
        main: '#40918f'
    },
    secondary: {
        main: '#f2b250'
    }
  }
});