import React, { Component } from "react";
import { connect } from "react-redux";
import validateObject from "../../helpers/validateObject.js";
import PageWrapper from "../HeaderFooterWrapper/";
import { Typography } from "@material-ui/core";
import ContactForm from "./ContactForm/contactForm.js";
import {getBasicInfo} from '../../store/BasicInformation/action.js'
class ContactPanel extends Component {
  componentDidMount() {
    this.props.getBasicInfo();
  }
  render() {
    const { basicInfo } = this.props;

    if (validateObject(basicInfo)) {
      const { address, email, phone, fax } = basicInfo;
      return (
        <div>
          <PageWrapper>
            <div>
              <Typography variant="h6">Location</Typography>
              <Typography variant="body1">{address.street}</Typography>
              <Typography variant="body1">
                {address.city}, {address.state}
              </Typography>

              <Typography variant="body1">{address.zip}</Typography>
            </div>
            <div>
              <Typography variant="h6">Contact Information</Typography>
              <Typography variant="body1">
                Phone: <a href={`tel:${phone}`}>{phone}</a>
              </Typography>
              <Typography variant="body1">
                Email: <a href={`mailto:${email}`}>{email}</a>
              </Typography>
              <Typography variant="body1">Fax: {fax}</Typography>
            </div>
            <ContactForm />
          </PageWrapper>
        </div>
      );
    }
    return <div></div>;
  }
}

const mapStateToProps = state => ({
  basicInfo: state.basicInfo
});
export default connect(mapStateToProps, {getBasicInfo})(ContactPanel);
