import React, { Component } from "react";
import { connect } from "react-redux";
import { getSchedules } from "../../store/schedule/action.js";
import PageWrapper from "../HeaderFooterWrapper/";
import { Typography } from "@material-ui/core";
import "./schedulePanel.style.css";
class SchedulePanel extends Component {
  componentDidMount() {
    this.props.getSchedules();
  }
  render() {
    const { schedules } = this.props;
    return (
      <PageWrapper>
        <div>
          {schedules.map(({ type, schedules }, i) => {
            return (
              <div className="schedule-container" key={i}>
                <Typography variant="h6">{type}</Typography>
                <div>
                  {schedules.map(({ name, frequency, language }, i) => {
                    return (
                      <Typography variant="body1" key={i}>
                        {name ? name + ' - ' : ''} {frequency} {language ? `- (${language})` : ""}
                      </Typography>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </PageWrapper>
    );
  }
}
const mapStateToProps = state => ({
  schedules: state.schedules
});
export default connect(mapStateToProps, { getSchedules })(SchedulePanel);
