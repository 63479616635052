import CMSClient from "../../services/CMSClientService.js";

const GET_HOME_IMAGES = "GET_HOME_IMAGES";
export const actions = { GET_HOME_IMAGES };

export function getHomeImagesAction(images) {
  return {
    type: actions.GET_HOME_IMAGES,
    images
  };
}

export function getHomeImages() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      CMSClient.getHomeImages()
        .then(({ data }) => {
          const images = data.map(d => {
            return {
              ...d,
              image: d.value.url
            };
          });
          dispatch(getHomeImagesAction(images));
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
}
export default {getHomeImages};
