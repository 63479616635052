import emailjs from "emailjs-com";
import notificationService from "./notificationService.js";
export function send(data) {
  emailjs
    .send("sendgrid", "template_szTJs5MS", data, "user_7ofd2txvaIQq7J40FOd47")
    .then(
      result => {
        notificationService.success("Contact Request Sent", true);
      },
      error => {
        notificationService.add(
          "Failed to send contact request",
          notificationService.NOTIFICATION_TYPES.error
        );
      }
    );
}

export default { send };
