import React, { Component } from "react";
import { Avatar, Card, Typography } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircleSharp";
import "./staff.style.css";
import { ContactMail } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import InfoButton from './infoButton.js'
class Staff extends Component {
  openContactModal() {}
  render() {
    const { role, firstname, lastname, phone, email, image } = this.props.staff;
    return (
      <Card className="staff-container flex-column-center">
        {image ? (
          <Avatar src={image} className="avatar" />
        ) : (
          <AccountCircle className="avatar" />
        )}

        <Typography variant="subtitle1">{role}</Typography>
        <Typography variant="body1">
          {firstname} {lastname}
        </Typography>
        <InfoButton contact={{phone, email}} />
      </Card>
    );
  }
}

export default Staff;
