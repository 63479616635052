import React, { Component } from "react";
import History from "./Sections/history";
import PageWrapper from "../HeaderFooterWrapper/";
import { getAbout } from "../../store/about/action.js";
import { connect } from "react-redux";
import Mission from "./Sections/mission.js";
import CMSClient from "../../services/CMSClientService.js";
import ImageWrapper from "../ImageWrapper/imageWrapper.js";

class AboutPage extends Component {
  componentDidMount() {
    this.props.getAbout();
  }
  render() {
    const { history, mission, image, hasImage } = this.props.about;
    return (
      <div>
        <PageWrapper className="flex-column-center" >
          {hasImage && <ImageWrapper src={image.url} />}
          <History history={history} />
          <Mission mission={mission} />
        </PageWrapper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  about: state.about
});
export default connect(mapStateToProps, { getAbout })(AboutPage);
