import { types } from "./action";

export default (state = [], action) => {
  switch (action.type) {
    case types.GET_SOCIAL_MEDIAS:
      return action.socialmedias;
    default:
      return state;
  }
};
