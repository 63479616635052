import { Switch, Route } from "react-router-dom";
import HomepageComponent from "../components/HomepageComponent/HomepageComponent.js";
import React from "react";
import AboutPage from "../components/AboutPage/aboutPage.js";
import ContactPage from "../components/ContactPanel/contactPanelComponent.js";
import MeetOurStaffPanel from "../components/MeetOurStaffPanel/meetOurStaffComponent.js";
import SchedulePanel from "../components/SchedulePanel/schedulePanelComponent.js";
import Page404 from "../components/ErrorHandling/ErrorPages/404";
import Page400 from "../components/ErrorHandling/ErrorPages/400";
import Page401 from "../components/ErrorHandling/ErrorPages/401";
const registerRoutes = BrowserRouter => {
  return props => {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={props => <HomepageComponent {...props} />}
          />
          <Route
            exact
            path="/celebrations"
            render={props => <HomepageComponent {...props} />}
          />
          <Route
            exact
            path="/events"
            render={props => <HomepageComponent {...props} />}
          />
          <Route path="/about" render={props => <AboutPage />} />
          <Route path="/contact" render={props => <ContactPage />} />
          <Route path="/staff" render={props => <MeetOurStaffPanel />} />
          <Route path="/schedules" render={props => <SchedulePanel />} />
          <Route
            path="/unauthorized"
            render={props => <Page401 {...props} />}
          />
          <Route path="/error" render={props => <Page400 {...props} />} />
          <Route render={props => <Page404 {...props} />} />
        </Switch>
        {props.children}
      </BrowserRouter>
    );
  };
};

export default registerRoutes;
