import CMSClient from "../../services/CMSClientService.js";
import dateHelper from "../../helpers/dateHelper.js";
const GET_EVENTS = "GET_EVENTS";
export const actions = { GET_EVENTS };

function getEventsAction(events) {
  return {
    type: actions.GET_EVENTS,
    events
  };
}

export function getEvents() {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        let { data: events } = await CMSClient.getEvents();
        let totalEventsForToday = events.length
          ? events.filter(
              e =>
                dateHelper.ignoreZ(e.datetime).dateStr ==
                new Date().toLocaleDateString()
            ).length
          : 0;

        dispatch(getEventsAction({ data: events, totalEventsForToday }));
        resolve(events);
      } catch (err) {
        reject(err);
      }
    });
  };
}
