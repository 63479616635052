import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { IconButton, Button } from "@material-ui/core";
import { ContactMail, Email, Phone } from "@material-ui/icons";
import { connect } from "react-redux";

function InfoButton({ contact, basicInfo }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { email, phone } = contact;
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "info-button" : undefined;
  let toEmail = email || basicInfo.email;
  let toPhone = phone || basicInfo.phone;
  return (
    <div>
      <IconButton
        aria-describedby={id}
        variant="contained"
        color="secondary"
        onClick={handleClick}
      >
        <ContactMail />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
      >
        <div className="flex-column-start contact-info">
          <Button href={`mailto:${toEmail}`} startIcon={<Email />}>
            Email
          </Button>
          <Button href={`tel:${toPhone}`} startIcon={<Phone />}>
            Call
          </Button>
        </div>
      </Popover>
    </div>
  );
}
const mapStateToProps = state => ({
  basicInfo: state.basicInfo
});
export default connect(mapStateToProps)(InfoButton);
