import React, { Component } from "react";
import { withRouter } from "react-router";
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    let hasReloaded = JSON.parse(localStorage.getItem("hasReloaded")) || false;
    if (!hasReloaded) {
      this.setState({ hasError: true }, () => {
        localStorage.hasReloaded = true;
        this.reload();
      });
    } else {
      localStorage.removeItem("hasReloaded");
      this.setState({ hasError: true }, () => {
        this.props.history.push("/error");
        window.location.reload();
      });
    }
  }
  reload() {
    window.location.reload();
  }

  render() {
    const { hasError } = this.state;
    return !hasError && this.props.children;
  }
}

export default withRouter(ErrorBoundary);
