import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ButtonLink from "../../ButtonLink/buttonLink.js";
import NotificationList from "../NotificationList/notificationListComponent.js";
import HomeImageGallery from "../HomeImageGallery/HomeImageGallery.js";
import { ReactComponent as LogoSVG } from "../logo.svg";
import "./header.styles.css";
import NavigationDrawerCollapsedMenu from "../NavigationDrawer/navigationDrawer.js";
import { withRouter } from "react-router";
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
  }

  updateDimensions = () => {
    if (window.innerWidth < 500) {
      this.setState({ width: 450, height: 102 });
    } else {
      let update_width = window.innerWidth - 100;
      let update_height = Math.round(update_width / 4.4);
      this.setState({ width: update_width, height: update_height });
    }
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }
  render() {
    const { location } = this.props;
    let isHome = ["/", "/celebrations", "/events"].includes(location.pathname);
    let minWidthExceeded = this.state.width <= 700;
    return (
      <div>
        <AppBar color="primary" position="static">
          <Toolbar className="flex-row-space-between">
            <ButtonLink to="/">St John Vianney Church</ButtonLink>

            <NavigationDrawerCollapsedMenu collapsed={minWidthExceeded}>
              <div>
                <ButtonLink to="/about" label="About" />
                <ButtonLink to="contact" label="contact" />
                <ButtonLink to="/staff" label="Meet Our Staff" />
                <ButtonLink to="/schedules" label="Schedules" />
              </div>
            </NavigationDrawerCollapsedMenu>
          </Toolbar>
        </AppBar>
        <NotificationList />
        {isHome ? (
          <div>
            <HomeImageGallery />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default withRouter(Navbar);
