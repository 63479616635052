import React, { Component } from "react";
import "./socialMediaBar.style.css";
import { Facebook, Call, Email } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { getSocialMedias } from "../../../store/SocialMedialLinks/action.js";
import { getBasicInfo } from "../../../store/BasicInformation/action.js";
import { connect } from "react-redux";

var icons = { facebook: Facebook };
class SocialMediaBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socialMediaOptions: []
    };
  }
  async componentDidMount() {
    let socialMediaOptions = (await this.props.getSocialMedias()).map(
      ({ name, link }) => {
        return { name, link, Icon: icons[name] };
      }
    );
    this.props.getBasicInfo();
    this.setState({ socialMediaOptions });
  }
  render() {
    const { socialMediaOptions } = this.state;
    const { email, phone } = this.props.basicInfo;
    return (
      <div id="sidebar">
        {socialMediaOptions.map(({ link, Icon }, i) => (
          <IconButton
            key={i}
            className="link-button"
            href={link}
            target="_blank"
          >
            <Icon />
          </IconButton>
        ))}
        <IconButton className="link-button" href={`mailto: ${email}`}>
          <Email />
        </IconButton>
        <IconButton className="link-button" href={`tel:${phone}`}>
          <Call />
        </IconButton>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  socialmedias: state.socialmedias,
  basicInfo: state.basicInfo
});
export default connect(mapStateToProps, { getSocialMedias, getBasicInfo })(
  SocialMediaBar
);
