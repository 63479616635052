import CMSClient from "../../services/CMSClientService.js";

const GET_BASIC_INFO = "GET_BASIC_INFO";

export const actions = { GET_BASIC_INFO };

function getBasicInfoAction(info) {
  return {
    type: actions.GET_BASIC_INFO,
    info
  };
}

export function getBasicInfo() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      CMSClient.getBasicInformation()
        .then(({ data }) => {
          const info = data[0];
          dispatch(getBasicInfoAction(info));
          resolve(info);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
}

export default { getBasicInfo };
