import { actions } from "./action.js";
const initialState = {
  today: { celebrations: [], hasCelebrations: false },
  tomorrow: { celebrations: [], hasCelebrations: false },
  yesterday: { celebrations: [], hasCelebrations: false }
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_CELEBRATIONS:
      return action.celebrations;
    default:
      return state;
  }
};
