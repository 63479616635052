import { CMSClient } from "../../services/";
import dateHelper from "../../helpers/dateHelper.js";
const GET_CELEBRATIONS = "GET_CELEBRATIONS";
export const actions = { GET_CELEBRATIONS };

function getCelebrationsAction(celebrations) {
  return {
    type: actions.GET_CELEBRATIONS,
    celebrations
  };
}

export function getCelebrations() {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        let celebrations = (await CMSClient.getCeleberations()).data.reduce(
          (acc, c, i) => {
            const currentDateStr = dateHelper.ignoreZ(c.date).dateStr;
            let tense =
              currentDateStr === dateHelper.now().dateStr
                ? "today"
                : currentDateStr === dateHelper.now(1).dateStr
                ? "tomorrow"
                : currentDateStr === dateHelper.now(-1).dateStr
                ? "yesterday"
                : "other";
            acc[tense].celebrations.push(c);

            return acc;
          },
          {
            today: { celebrations: [] },
            yesterday: { celebrations: [] },
            tomorrow: { celebrations: [] },
            other: { celebrations: [] }
          }
        );
        const totalCelebrationsToday = celebrations.today.celebrations
          ? celebrations.today.celebrations.length
          : 0;
        celebrations.today["hasCelebrations"] = !!celebrations.today
          .celebrations.length;
        celebrations.today["date"] = dateHelper.now().dateStr;

        celebrations.tomorrow["hasCelebrations"] = !!celebrations.tomorrow
          .celebrations.length;
        celebrations.tomorrow["date"] = dateHelper.now(1).dateStr;

        celebrations.yesterday["hasCelebrations"] = !!celebrations.yesterday
          .celebrations.length;
        celebrations.yesterday["date"] = dateHelper.now(-1).dateStr;
        let data = { ...celebrations, totalCelebrationsToday };
        dispatch(getCelebrationsAction(data));
        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  };
}
