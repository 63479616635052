import React, { Component } from "react";
import { connect } from "react-redux";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Notification from "./CelebrationNotification/notification.js";
import "./celebrationNotification.style.css";

class CelebrationNotification extends Component {
  render() {
    const { today, tomorrow, yesterday } = this.props.celebrations;
    return (
      <div className="celebration-notification-container">
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="h6">Yesterday's {yesterday.date}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="flex-column-start">
              {yesterday.hasCelebrations
                ? yesterday.celebrations.map((c, i) => {
                    return <Notification key={i} notification={c} />;
                  })
                : ""}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">Today's {today.date}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="flex-column-start">
              {today.hasCelebrations
                ? today.celebrations.map((c, i) => {
                    return <Notification key={i} notification={c} />;
                  })
                : ""}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="h6">Tomorrow's {tomorrow.date}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="flex-column-start">
              {tomorrow.hasCelebrations
                ? tomorrow.celebrations.map((c, i) => {
                    return <Notification key={i} notification={c} />;
                  })
                : ""}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  celebrations: state.celebrations
});

export default connect(mapStateToProps)(CelebrationNotification);
