import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import ImageWrapper from "../../ImageWrapper/imageWrapper.js";
import Iframe from "react-iframe";

class PostTabPanel extends Component {
  render() {
    const { post } = this.props;
    //console.log(post);
    return (
      <div className="flex-column-center">
        <Typography variant="h5">{post.title}</Typography>
        {post.pictures[0] ? <ImageWrapper src={post.pictures[0]} /> : ""}
        {post.youtube_url ? (
          <Iframe
            url={post.youtube_url.replace("watch?v=", "embed/")}
            width="650px"
            height="450px"
            id="youtube_embedded"
            display="initial"
            position="relative"
          />
        ) : (
          ""
        )}
        <Typography variant="body1">{post.body}</Typography>
        {post.external_links ? <Typography>External Links:</Typography> : ""}
        {post.external_links.split(",").map((l, i) => (
          <a href={l} key={i} target="_blank" rel="noopener noreferrer">
            {l}
          </a>
        ))}
      </div>
    );
  }
}

export default PostTabPanel;
