const ADD_POST_TAB = "ADD_POST_TAB";
const GET_POST_TABS = "GET_POST_TABS";
const REMOVE_POST_TAB = "REMOVE_POST_TAB";
export const actions = { GET_POST_TABS, ADD_POST_TAB, REMOVE_POST_TAB };

export function addPostTabAction(post) {
  return {
    type: actions.ADD_POST_TAB,
    post
  };
}

export function getPostTabsAction() {
  return {
    type: actions.GET_POST_TABS
  };
}

export function deletePostTabAction(post) {
  return {
    type: actions.REMOVE_POST_TAB,
    post
  };
}

export function removePostTab(post) {
  return dispatch => {
    return dispatch(deletePostTabAction(post));
  };
}

export default {
  actions,
  getPostTabsAction,
  addPostTabAction,
  deletePostTabAction,
  removePostTab
};
