import React, { Component } from "react";
import { connect } from "react-redux";
import { getHomeImages } from "../../../store/HomeImages/action.js";
import AwesomeSlider from "react-awesome-slider";
import AwsSliderStyles from "react-awesome-slider/src/styled/fold-out-animation";
import "./ImageGallery.styles.css";
class HomeImageGallery extends Component {
  componentDidMount() {
    this.props.getHomeImages();
  }
  render() {
    const { images } = this.props;
    return (
      <div className="home-image-gallery">
        <AwesomeSlider
          loader-bar-color="#f2b250"
          bullets={true}
          className="image-gallery"
          cssModule={AwsSliderStyles}
        >
          {images.map(img => {
            return <div key={img.id} data-src={img.image}></div>;
          })}
        </AwesomeSlider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    images: state.homeImages
  };
}

export default connect(mapStateToProps, { getHomeImages })(HomeImageGallery);
