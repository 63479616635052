import { actions } from "./action.js";


export default (state = {data : [], totalEventsForToday: 0}, action)=>{
    switch(action.type){
        case actions.GET_EVENTS:
            return action.events;
        default:
            return state;
    }
}