import React, { Component } from "react";
import { connect } from "react-redux";
import Calendar from "react-calendar";
import "./eventPanel.style.css";
import {
  ArrowBackIos,
  FastForward,
  FastRewind,
  ArrowForwardIos
} from "@material-ui/icons";
import dateHelper from "../../../helpers/dateHelper";
import EventItem from "./EventItem/eventItem.js";
import {Badge, Typography} from '@material-ui/core'

class EventPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date()
    };
  }
  onChange = date => {
    this.setState({ date });
  };

  render() {
    const { date } = this.state;  
    const events = this.props.events.data
      .filter(({ datetime }) => {
        //  GETTING JUST THE EVENTS WITH MATCHING DATE AS THE CALENDAR
        let { dateStr: eventTimeStr } = dateHelper.ignoreZ(datetime);
        let currentDateStr = new Date(date).toLocaleDateString();
        return eventTimeStr == currentDateStr;
      })
      .map(e => {
        // SETTIMG THE TIME PROPERTY
        let { date } = dateHelper.ignoreZ(e.datetime);
        let time = date.toLocaleTimeString();
        e["time"] = time;

        return e;
      })
      // SORTING THE EVENTS BY TIME
      .sort((e1, e2) =>new Date("1970/01/01 " + e1.time) - new Date("1970/01/01 " + e2.time));
    return (
      <div className="flex-row-start flex-wrap">
        <Calendar
          className="calendar"
          tileClassName="tile"
          prevLabel={<ArrowBackIos />}
          prev2Label={<FastRewind />}
          nextLabel={<ArrowForwardIos />}
          next2Label={<FastForward />}
          onChange={this.onChange}
          value={this.state.date}
        />
        <div className="flex-row-start flex-wrap">
          {events.length ? events.map((e, i) => (
            <EventItem key={i} {...e} />
          )): <Typography variant="body1">There are no events</Typography>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  events: state.events
});

export default connect(mapStateToProps, {})(EventPanel);
