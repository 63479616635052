import CMSClient from "../../services/CMSClientService.js";
import { AssignmentReturnOutlined } from "@material-ui/icons";

const GET_SOCIAL_MEDIAS = "GET_SOCIAL_MEDIAS";

export const types = { GET_SOCIAL_MEDIAS };
function getSocialMediasLinkAction(socialmedias) {
  return {
    type: types.GET_SOCIAL_MEDIAS,
    socialmedias
  };
}

export function getSocialMedias() {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        let { data: socialmedias } = await CMSClient.getSocialMedias();
        dispatch(getSocialMediasLinkAction(socialmedias));
        resolve(socialmedias);
      } catch (err) {
        reject(err);
      }
    });
  };
}
