import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Header from "../header/header.js";
import SocialMediaBar from "../SocialMediaBar/socialMediaBar.js";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.stjohnvianneychurch.com">
        StJohnVianneyChurch
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  main: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(1)
  },
  footer: {
    padding: theme.spacing(2),
    marginTop: "auto",
    backgroundColor: "white"
  }
}));

export default function StickyFooter(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header />
      {props.children}
      <CssBaseline />
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <SocialMediaBar />
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}
