import store from "../store/";
import Notification from "../store/Notification/action.js";

export const NOTIFICATION_TYPES = {
  error: "error",
  success: "success",
  info: "info",
  warning: "warning"
};

export function add(message,type = NOTIFICATION_TYPES.info,clearPrev = false) {
  const notification = {
    message,
    type
  };
  if (clearPrev) {
    removeAll();
  }
  store.dispatch(Notification.addNotificationAction(notification));
}

export function error(message, removePrev = false) {
  add(message, NOTIFICATION_TYPES.error, removePrev);
}

export function info(message, removePrev = false) {
  add(message,NOTIFICATION_TYPES.info, removePrev);
}

export function warning(message, removePrev = false) {
  add(message,NOTIFICATION_TYPES.warning, removePrev);
}

export function success(message, removePrev = false) {
  add(message,NOTIFICATION_TYPES.success, removePrev);
}

export function remove(notification) {
  store.dispatch(Notification.removeNotificationAction(notification));
}

export function removeAll() {
  store.dispatch(Notification.removeAllNotification());
}

export default { NOTIFICATION_TYPES, add, remove, removeAll , error, info, success, warning};
