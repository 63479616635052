import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import posts from "./Posts/reducer.js";
import homeImages from "./HomeImages/reducer";
import basicInfo from "./BasicInformation/reducer";
import about from "./about/reducer.js";
import schedules from "./schedule/reducer.js";
import staffs from "./staff/reducer.js";
import notifications from "./Notification/reducer";
import celebrations from "./Celebration/reducer";
import events from "./Event/reducer.js";
import socialmedias from "./SocialMedialLinks/reducer.js";
import verseOfTheDay from './VerseOfTheDay/reducer'
import postTabs  from './PostTabs/reducer.js'
const rootReducer = combineReducers({
  posts,
  homeImages,
  basicInfo,
  about,
  schedules,
  staffs,
  notifications,
  celebrations,
  events,
  socialmedias,
  verseOfTheDay,
  postTabs
});

// enabling redux dev tool in dev only
const enableReduxDevTool =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
var middlewares =
  process.env.NODE_ENV === "development"
    ? compose(applyMiddleware(thunk), enableReduxDevTool)
    : compose(applyMiddleware(thunk));

export const configureStore = () => {
  return createStore(rootReducer, middlewares);
};

const store = configureStore();
export default store;
