export function now(add = 0) {
  const now = new Date();
  now.setDate(now.getDate() + add);
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const date = now.getDate();
  const day = now.getDay();
  const dayOfWeek = day.toLocaleString();
  const dateStr = now.toLocaleDateString();
  return { year, month, date, day, dayOfWeek, dateStr };
}

export function ignoreZ(dateStr) {
  let date = new Date(dateStr.replace('Z', '')); // removing the 'Z' from the end of the date time string

  return  {date, dateStr: date.toLocaleDateString()}
}

export default { now, ignoreZ };
