import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { Menu, Info, Phone, Group, Event } from "@material-ui/icons";
import "./navigationDrawer.style.css";
const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
});

export default function NavigationMenuCollapsed({ collapsed, children }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };
  const options = [
    { icon: Info, text: "About", to: "/about" },
    { icon: Phone, text: "Contact", to: "/contact" },
    { icon: Group, text: "Staff", to: "/staff" },
    { icon: Event, text: "Schedule", to: "/schedules" }
  ];
  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        {options.map(({ text, icon: Icon, to }) => (
          <Link className="navigation-link" key={text} to={to}>
            <ListItem button >
              <ListItemIcon>
                <Icon color="primary"/>
              </ListItemIcon>
              <ListItemText color="primary" primary={text} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );
  if (!collapsed) {
    return children;
  }
  return (
    <div>
      <IconButton className="menu-button" onClick={toggleDrawer("right", true)}>
        <Menu />
      </IconButton>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
      >
        {sideList("right")}
      </Drawer>
    </div>
  );
}
