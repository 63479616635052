import cmsClient from "./CMSClientService.js";
import emailService from "./emailService.js";
import notificationService from "./notificationService.js";

export const CMSClient = cmsClient;
export const EmailService = emailService;
export const NotificationService = notificationService;

export default {
  CMSClient,
  EmailService,
  NotificationService
};
