import CMSClient from "../../services/CMSClientService.js";

const GET_STAFF = "GET_STAFF";

export const actions = { GET_STAFF };

function getStaffsAction(staffs) {
  return {
    type: actions.GET_STAFF,
    staffs
  };
}

export function getStaffs() {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        let { data: staffs } = await CMSClient.getStaffs();
        staffs = staffs.map(s => {
          if (s.profile_image) {
            s["image"] = CMSClient.getImage(s.profile_image);
          }

          return s;
        });
        dispatch(getStaffsAction(staffs));
        resolve(staffs);
      } catch (err) {
        reject(err);
      }
    });
  };
}
