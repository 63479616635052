const ADD_NOTIFICATION = "ADD_NOTIFICATION";
const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
const REMOVE_ALL_NOTIFCATION = "REMOVE_ALL_NOTIFCATION";

export const types = {
  REMOVE_ALL_NOTIFCATION,
  REMOVE_NOTIFICATION,
  ADD_NOTIFICATION
};


export function addNotificationAction(notification) {
  return {
    type: types.ADD_NOTIFICATION,
    notification
  };
}

export function removeNotificationAction(notification) {
  return {
    type: types.REMOVE_NOTIFICATION,
    notification
  };
}

export function removeAllNotification() {
  return {
    type: types.REMOVE_ALL_NOTIFCATION
  };
}

export default {addNotificationAction, removeNotificationAction, removeAllNotification}
