import React, { Component } from "react";
import FooterWrapper from "./Footer/footerWrapper.js";
import { Paper } from "@material-ui/core";
import "./index.css";
import ErrorBoundary from "../ErrorHandling/errorHandling";
class PageWrapper extends Component {
  render() {
    const { className } = this.props;
    return (
      <ErrorBoundary>
        <FooterWrapper>
          <Paper className={`content-container ${className}`}>
            {this.props.children}
          </Paper>
        </FooterWrapper>
      </ErrorBoundary>
    );
  }
}

export default PageWrapper;
