import React, { Component } from "react";
import { connect } from "react-redux";
import { getStaffs } from "../../store/staff/action.js";
import PageWrapper from "../HeaderFooterWrapper/";
import "./meetOurStaff.style.css";
import Staff from "./Staff/staff.js";
class MeetOurStaffPanel extends Component {
  componentDidMount() {
    this.props.getStaffs();
  }
  render() {
    const { staffs } = this.props;
    return (
      <PageWrapper >
        <div className="flex-row-start flex-wrap">
          {staffs.map((s, i) => {
            return <Staff key={i} staff={s} />;
          })}
        </div>
      </PageWrapper>
    );
  }
}
const mapStateToProps = state => ({
  staffs: state.staffs
});
export default connect(mapStateToProps, { getStaffs })(MeetOurStaffPanel);
