import { types } from "./action.js";

export default (state = [], action) => {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      return [...state, action.notification];
    case types.REMOVE_NOTIFICATION:
      return state.filter(n => n.message !== action.notification.message);
    case types.REMOVE_ALL_NOTIFCATION:
      return [];
    default:
      return state;
  }
};
