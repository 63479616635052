import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Post from "./Post/Post.js";
import { Typography } from "@material-ui/core";
import "./PostsList.styles.css";
class PostsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  render() {
    const { posts } = this.props;
    return (
      <div>
        <div className="flex-row-start posts flex-wrap">
          {posts.hasPosts ? (
            posts.posts.map(p => {
              return <Post key={p.id} post={p}  />;
            })
          ) : (
            <Typography variant="body1">
              There is no posts. please check again later
            </Typography>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  posts: state.posts
});

export default connect(mapStateToProps, {})(PostsList);
