import { actions } from "./actions.js";

export default (state = [], action) => {
  switch (action.type) {
    case actions.GET_POST_TABS:
      return state;
    case actions.ADD_POST_TAB:
      if (state.some(p => p.id == action.post.id)) {
        return state;
      }
      return [...state, action.post];
    case actions.REMOVE_POST_TAB:
      return state.filter(p => p.id !== action.post.id);
    default:
      return state;
  }
};
