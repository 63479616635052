import React, { Component } from "react";
import { connect } from "react-redux";
import Notification from "./Notification/notification.js";
import NotificationService from "../../../services/notificationService.js";
class NotificationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: []
    };
  }

  componentWillReceiveProps = props => {
    const { notifications } = props;
    this.setState({ notifications });
  };

  nextNotification =  () => {
    NotificationService.remove(this.state.notifications[0]); // removing the notification after it has been displayed
    return this.state.notifications.length !== 0; // return boolean indicating whether there are more notifications to display
  };
  render() {
    const { notifications } = this.state;
    const currentNotification = notifications[0];
    return (
      <div>
        {currentNotification ? (
          <Notification
            nextNotification={this.nextNotification}
            notification={currentNotification}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications
});

export default connect(mapStateToProps)(NotificationList);
