import CMSClient from '../../services/CMSClientService.js'
import { object } from 'prop-types';

const GET_ABOUT = 'GET_ABOUT';

export const actions = {GET_ABOUT};


function getAboutAction(about){
    return{
        type: actions.GET_ABOUT,
        about
    }
}

export function getAbout(){
    return (dispatch, getState) =>{
        return new Promise(async (resolve, reject) => {
            try{
                const {data: about} = await CMSClient.getAbout();
                const hasImage =  !!(about[0].image && Object.values(about[0].image) && about[0].image.url.length)
                dispatch(getAboutAction({...about[0], hasImage}))
                resolve(about[0])
            }catch(err){
                reject(err);
            }
        })
        
    }
}