import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import "./buttonLink.style.css";
import Typography from "@material-ui/core/Typography";

function createLink() {
  return React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));
}

export default function ButtonLink({ to, label , children}) {
  return (
    <Button className="navigation-button" component={createLink()} to={to}>
      <Typography variant="h6" noWrap>
        {label || children}
      </Typography>
    </Button>
  );
}
