// this service provides access to CMS for content management
import httpHelper from "../helpers/httpRequestHelper.js";
require("dotenv").config();

const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/strapi"
    : `${process.env.REACT_APP_PROXY_URL}/strapi`
    

export const TYPES = {
  posts: "posts",
  homeimages: "homeimages",
  basicinformations: "basicinfo",
  masses: "massschedules",
  reconciliations: "reconciliationschedules",
  eucharisticadorations: "eucharisticadorations",
  histories: "histories",
  staffs: "staffs",
  about: "about",
  celebrations: "celebrations",
  events: "events",
  socialmedias: "socialmedias"
};

export async function getContentOfType(type) {
  return httpHelper.send(httpHelper.METHODS.get, `${baseURL}/${type}`);
}
export async function getPosts() {
  return getContentOfType(TYPES.posts);
}
export async function getHomeImages() {
  return getContentOfType(TYPES.homeimages);
}
export function getImage(pictureObj) {
  return `${baseURL}${pictureObj.url}`;
}



export async function getBasicInformation() {
  return getContentOfType(TYPES.basicinformations);
}

export async function getMassSchedules() {
  return getContentOfType(TYPES.masses);
}
export async function getReconciliationSchedule() {
  return getContentOfType(TYPES.reconciliations);
}

export async function getEucharisticAdorations() {
  return getContentOfType(TYPES.eucharisticadorations);
}

export async function getAbout() {
  return getContentOfType(TYPES.about);
}

export async function getStaffs() {
  return getContentOfType(TYPES.staffs);
}

export async function getCeleberations() {
  return getContentOfType(TYPES.celebrations);
}

export async function getEvents() {
  return getContentOfType(TYPES.events);
}

export async function getSocialMedias() {
  return getContentOfType(TYPES.socialmedias);
}

export default {
  getPosts,
  getImage,
  getHomeImages,
  getBasicInformation,
  getAbout,
  getStaffs,
  getContentOfType,
  getEucharisticAdorations,
  getMassSchedules,
  getReconciliationSchedule,
  getCeleberations,
  getEvents,
  getSocialMedias
};
