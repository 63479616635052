import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/";
import hocStore from "./hoc";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
function App() {
  const RoutedComponent = hocStore.registerRoutes(BrowserRouter); //  registering routes with the browser router
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
          <RoutedComponent />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
