import bibleAPI from "../../services/bibleApi.js";

const GET_VERSE_OF_THE_DAY = "GET_VERSE_OF_THE_DAY";

export const actions = { GET_VERSE_OF_THE_DAY };

function getVerseOfTheDayAction(verse) {
  return {
    type: actions.GET_VERSE_OF_THE_DAY,
    verse
  };
}

export function getVerseOfTheDay(){
    return dispatch=> {
        return new Promise(async (resolve, reject) => {
            try{
                let verse = await bibleAPI.randomVerse();
                dispatch(getVerseOfTheDayAction(verse));
                resolve(verse);
            }catch(err){
                reject(err);
            }
        })
        
    }
}
