import CMSClient from "../../services/CMSClientService.js";
import dateHelper from "../../helpers/dateHelper.js";
const GET_POSTS = "GET_POSTS";
export const actions = { GET_POSTS };

function GetPostsAction(data) {
  return {
    type: GET_POSTS,
    data
  };
}
export function getPosts() {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        let posts = await CMSClient.getPosts();
        let totalPostsUpdatedToday = 0;
        let withStatus = posts.data.map(p => {
          let status =
            dateHelper.ignoreZ(p.created_at).dateStr ==
            new Date().toLocaleDateString()
              ? "New"
              : dateHelper.ignoreZ(p.updated_at).dateStr ==
                new Date().toLocaleDateString()
              ? "Updated"
              : "";
          p["status"] = status;
          p.pictures = p.preview_picture.map(pic => pic.url);
          if (
            dateHelper.ignoreZ(p.updated_at).dateStr ==
            new Date().toLocaleDateString()
          ) {
            totalPostsUpdatedToday++;
          }
          return p;
        });

        let hasPosts = !!withStatus.length;

        dispatch(
          GetPostsAction({
            posts: withStatus,
            hasPosts,
            totalPostsUpdatedToday
          })
        );
        resolve(withStatus);
      } catch (err) {
        reject(err);
      }
    });
  };
}

export default { getPosts };
