import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Badge } from "@material-ui/core/";
import { useDispatch, useSelector } from "react-redux";
import { addPostTabAction } from "../../../../store/PostTabs/actions";
import "./Post.styles.css";
const useStyles = makeStyles({
  card: {
    maxWidth: 345
  },
  media: {
    height: 140
  }
});

export default function Post({ post }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const openPostTab = () => {
    dispatch(addPostTabAction(post));
  };

  return (
    <div>
      <Badge
        color="secondary"
        badgeContent={post.status}
        invisible={!post.status}
      >
        <Card onClick={openPostTab} className={`${classes.card} post`}>
          <CardActionArea>
            {post.pictures[0] ? (
              <CardMedia
                className={classes.media}
                image={post.pictures[0]}
                title={post.title}
              />
            ) : (
              ""
            )}
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {post.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {post.body.slice(0, 200)}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Badge>
    </div>
  );
}
