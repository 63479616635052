import React from "react";
import { Card, Typography } from "@material-ui/core";
import './eventItem.style.css'
export default ({ name, time }) => {
  return (
    <Card className= 'event-item'>
      <Typography variant="body1">
        {name} - {time}
      </Typography>
    </Card>
  );
};
