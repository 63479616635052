import React, { PureComponent, Fragment } from "react";
import PageWrapper from "../HeaderFooterWrapper/";
import "./HomepageComponent.styles.css";
import HomepageNavigationBar from "./HomepageNavigationTabs/homepageNavigationTab.js";

class HomepageComponent extends PureComponent {
  render() {
    return (
      <div>
        <PageWrapper>
          <HomepageNavigationBar />
        </PageWrapper>
      </div>
    );
  }
}

export default HomepageComponent;
