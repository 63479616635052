import React from "react";
import { Typography } from "@material-ui/core";
export default ({ mission }) => {
  return (
    <div>
      <div>
        <Typography variant="h6">Mission</Typography>
        <Typography>{mission}</Typography>
      </div>
    </div>
  );
};
